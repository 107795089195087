import {
  ArrowSmRightIcon,
  ArrowSmLeftIcon,
  DeviceMobileIcon,
} from "@heroicons/react/outline";
import React, { Fragment, useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import {
  newSubscribe,
  verifySubscribe,
} from "./../../services/subscribe.service";
import LoadingButtonIcon from "./../../components/LoadingButtonIcon";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/actions/authActions";
import { clearUserData, responseErrorHandler } from "./../../utils/common";

export default function Payment({ priceId }) {
  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang);

  const [modalOpen, setModalOpen] = useState(false);
  const [submittingSubscription, setSubmittingSubscription] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [submittingOtp, setSubmittingOtp] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false);
  const [subscribeRequestId, setSubscribeRequestId] = useState("");

  const cancelButtonRef = useRef(null);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const PaymentSchema = Yup.object().shape({
    // firstName: Yup.string()
    //   .trim()
    //   .min(2, "First name must be 4 characters at minimum")
    //   .required("First name is required"),
    // lastName: Yup.string()
    //   .trim()
    //   .min(2, "Last name must be 2 characters at minimum")
    //   .required("Last name is required"),
    // email: Yup.string()
    //   .email("Email must be in a valid format")
    //   .required("Email address is required"),
    phoneNumber: Yup.string()
      .matches(/^(([1]\d{8})|([9]\d{8}))$/, "Phone number is not valid")
      .required("Phone number is required"),
  });

  const OtpSchema = Yup.object().shape({
    otp: Yup.string().required("OTP is required"),
  });

  const handleSubscribeSubmit = (submittedPhoneNumber) => {
    setSubmittingSubscription(true);
    setPhoneNumber(submittedPhoneNumber);
    newSubscribe(submittedPhoneNumber, priceId)
      .then((response) => {
        setSubmittingSubscription(false);
        if (response.status === 201) {
          setSubscribeRequestId(response.data.data.subscribeRequestId);
          console.log("subscribeRequestId: ", subscribeRequestId);
          setModalOpen(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          clearUserData();
          navigate("/login?logout=session-timeout");
        } else {
          responseErrorHandler(error, lang);
        }
      });
  };

  const handleOtpSubmit = (otp) => {
    console.log("otp: ", otp);
    console.log("subscribeRequestId: ", subscribeRequestId);
    setSubmittingOtp(true);
    setWrongOtp(false);
    verifySubscribe(subscribeRequestId, otp, priceId)
      .then((response) => {
        setSubmittingOtp(false);
        if (response.status === 201) {
          let updatedUser = user;
          updatedUser.subscriptions = [response.data.data];
          setModalOpen(false);
          dispatch(updateUser(updatedUser));
          navigate("/");
        }
      })
      .catch((error) => {
        if (error.response.status === 409) {
          setWrongOtp(true);
          setSubmittingOtp(false);
        } else if (error.response.status === 401) {
          clearUserData();
          navigate("/login?logout=session-timeout");
        } else {
          responseErrorHandler(error, lang);
        }
      });
  };

  return (
    <>
      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setModalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-dark rounded-lg px-4 pt-5 pb-4 ltr:text-left rtl:text-right overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <Formik
                  initialValues={{
                    otp: "",
                  }}
                  validationSchema={OtpSchema}
                  onSubmit={(values) => handleOtpSubmit(values.otp)}
                >
                  {({ touched, errors }) => (
                    <Form>
                      <div>
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-shilail-100">
                          <DeviceMobileIcon
                            className="h-6 w-6 text-shilail-500"
                            aria-hidden="true"
                          />
                        </div>

                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-white"
                          >
                            {lang === "en" ? "OTP verification" : "رمز التحقق"}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-400">
                              {lang === "en"
                                ? "An OTP (One Time Password) has been sent to your mobile number."
                                : "تم ارسال رمز التحقق (ارقام) الى رقم الهاتف الذى ادخلته."}
                            </p>
                          </div>
                          {wrongOtp && (
                            <div className="mt-2 -mb-2">
                              <p className="text-sm text-red-500">
                                {lang === "en"
                                  ? "Wrong OTP!"
                                  : "رمز التحقق خطأ!"}
                              </p>
                            </div>
                          )}
                        </div>
                        <Field
                          type="text"
                          name="otp"
                          id="otp"
                          autoComplete="off"
                          className={`mt-4 shadow-sm block w-full sm:text-sm rounded-md bg-dark text-white text-center ${
                            touched.otp && errors.otp
                              ? "border-red-700 focus:ring-red-500 focus:border-red-500"
                              : "border-gray-700 focus:ring-shilail-400 focus:border-shilail-400"
                          }`}
                          placeholder="Enter OTP"
                        />
                      </div>
                      <button
                        type="submit"
                        className="mt-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-shilail-500 text-base font-medium text-white hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 sm:col-start-2 sm:text-sm"
                      >
                        {submittingOtp ? (
                          <LoadingButtonIcon />
                        ) : lang === "en" ? (
                          "Verify"
                        ) : (
                          "ادخال"
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Formik
        initialValues={{
          // firstName: user.firstName || "",
          // lastName: user.lastName || "",
          // email: user.email || "",
          phoneNumber: user.phoneNumber || "",
        }}
        validationSchema={PaymentSchema}
        onSubmit={(values) => handleSubscribeSubmit(values.phoneNumber)}
      >
        {({ touched, errors }) => (
          <Form>
            <div className="text-base font-medium text-lg text-white">
              {lang === "en" ? "Fill form" : "املأ حقول الاشتراك"}
            </div>
            <h5 className="text-sm leading-6 font-medium text-white text-opacity-20">
              {lang === "en"
                ? "To subscribe please fill all required fields"
                : "رجاءً اضف جميع الخانات المطلوبة"}
            </h5>

            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
              {/* <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-white text-opacity-50"
                >
                  First name
                </label>
                <Field
                  type="text"
                  name="firstName"
                  id="first-name"
                  autoComplete="given-name"
                  className={`shadow-sm block w-full sm:text-sm rounded-md bg-dark text-white ${
                    touched.firstName && errors.firstName
                      ? "border-red-700 focus:ring-red-500 focus:border-red-500"
                      : "border-gray-700 focus:ring-shilail-400 focus:border-shilail-400"
                  }`}
                />
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-white text-opacity-50"
                >
                  Last name
                </label>
                <Field
                  type="text"
                  name="lastName"
                  id="last-name"
                  autoComplete="family-name"
                  className={`shadow-sm block w-full sm:text-sm rounded-md bg-dark text-white ${
                    touched.lastName && errors.lastName
                      ? "border-red-700 focus:ring-red-500 focus:border-red-500 border-2"
                      : "border-gray-700 focus:ring-shilail-400 focus:border-shilail-400"
                  }`}
                />
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email-address"
                  className="block text-sm font-medium text-white text-opacity-50"
                >
                  Email address
                </label>
                <Field
                  type="text"
                  name="email"
                  id="email-address"
                  autoComplete="email"
                  className={`shadow-sm block w-full sm:text-sm rounded-md bg-dark text-white ${
                    touched.email && errors.email
                      ? "border-red-700 focus:ring-red-500 focus:border-red-500"
                      : "border-gray-700 focus:ring-shilail-400 focus:border-shilail-400"
                  }`}
                />
              </div> */}

              <div className="sm:col-span-3">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium text-white text-opacity-50"
                >
                  {lang === "en" ? "Phone number" : "رقم الهاتف"}
                </label>
                <div className="relative rounded-md shadow-sm text-white">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-white text-opacity-70 sm:text-sm text-left">
                      249
                    </span>
                  </div>
                  <Field
                    type="text"
                    name="phoneNumber"
                    id="phone-number"
                    className={`block w-full pl-16 sm:pl-12 sm:text-sm rounded-md text-left text-white bg-dark ${
                      touched.phoneNumber && errors.phoneNumber
                        ? "focus:ring-red-500 focus:border-red-500 border-red-700"
                        : "border-gray-700 focus:ring-shilail-400 focus:border-shilail-400"
                    }`}
                  />
                </div>
              </div>
            </div>

            <div className="bg-dark shadow sm:rounded-lg mt-8">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-white">
                  {lang === "en"
                    ? "Subscription confirmation"
                    : "تأكيد الإشتراك"}
                </h3>
                <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                  <div className="max-w-xl text-sm text-white text-opacity-70">
                    <p>
                      {lang === "en"
                        ? "You can cancel your subscription anytime through your profile settings page."
                        : "يمكنك إلغاء الإشتراك في اي وقت عن طريق صفحة اعدادات الاشتراك."}
                    </p>
                  </div>
                  <div className="mt-5 sm:mt-0 ltr:sm:ml-6 rtl:sm:mr-6 sm:flex-shrink-0 sm:flex sm:items-center">
                    <button
                      type="submit"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 disabled:opacity-10"
                    >
                      {!submittingSubscription ? (
                        <>
                          {lang === "en" ? "Subscribe" : "الإشتراك"}
                          {lang === "en" ? (
                            <ArrowSmRightIcon
                              className="ltr:-mr-1 ltr:ml-2 rtl:-ml-1 rtl:mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          ) : (
                            <ArrowSmLeftIcon
                              className="ltr:-mr-1 ltr:ml-2 rtl:-ml-1 rtl:mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                        </>
                      ) : (
                        <LoadingButtonIcon />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

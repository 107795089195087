import React, { useEffect, useState } from "react";
import Navbar from "../../partials/navbar";
import Sidebar from "../../partials/sidebar";
import { playGame } from "./../../services/game.service";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import LoadingIcon from "../../components/LoadingIcon";
import { clearUserData, responseErrorHandler } from "./../../utils/common";
import { useNavigate, useLocation } from "react-router-dom";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { ArrowCircleLeftIcon } from "@heroicons/react/solid";
import { SearchIcon, SparklesIcon } from "@heroicons/react/solid";
import * as subscribeActions from "../../redux/actions/subscribeActions";

export default function GamePlay() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState(null);

  const authenticated = useSelector((state) => state.auth.authenticated);
  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang);
  const subscription = useSelector((state) => state.subscription);

  let { id } = useParams();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let navigate = useNavigate();
  const dispatch = useDispatch();
  let query = useQuery();

  const gamesUrl = process.env.REACT_APP_GAMES_URL;
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchGame = () => {
    playGame(id)
      .then((response) => {
        if (response.status === 200) {
          setGame(response.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          clearUserData();
          navigate("/login?logout=session-timeout");
        } else {
          responseErrorHandler(error, lang);
        }
      });
  };

  const resizeIframe = () => {
    setWindowHeight(window.innerHeight - 64);
    setWindowWidth(window.innerWidth);

    // Disabled after removing sidebar
    // if (window.innerWidth > 1023) {
    //   setWindowWidth(window.innerWidth - 273);
    // } else {
    //   setWindowWidth(window.innerWidth);
    // }
  };

  useEffect(() => {
    checkSubscriptionStatus();

    if (!game) {
      fetchGame();
    }

    resizeIframe();

    window.addEventListener("resize", resizeIframe);
    return () => window.removeEventListener("resize", resizeIframe);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkSubscriptionStatus() {
    if (query.get("msisdn")) {
      checkSubscription(query.get("msisdn"));
    } else if (subscription.subscription !== null) {
      if (!isDateToday(subscription.subscribeDate)) {
        dispatch(subscribeActions.logout());
      }
    }
  }

  const checkSubscription = (phoneNumber) => {
    //check subscription
    setLoading(true);
    dispatch(subscribeActions.checkSubscription(phoneNumber));
  };

  function isDateToday(date) {
    const otherDate = new Date(date);
    const todayDate = new Date();

    if (
      otherDate.getDate() === todayDate.getDate() &&
      otherDate.getMonth() === todayDate.getMonth() &&
      otherDate.getYear() === todayDate.getYear()
    ) {
      return true;
    } else {
      return false;
    }
  }

  const redirectToSubscribe = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <button
        onClick={() => navigate("/")}
        className="mt-20 absolute left-5 top-0"
      >
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <ArrowCircleLeftIcon className="h-10 w-10 text-amber-400" />
      </button>
      <React.Fragment>
        <Navbar setSidebarOpen={setSidebarOpen} />
        {/* <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}> */}
        <main className="flex flex-col flex-1 lg:pt-16 bg-gaming">
          {loading ? (
            <LoadingIcon />
          ) : subscription.subscription !== null ? (
            <iframe
              id="game-iframe"
              height={windowHeight}
              style={{ width: windowWidth }}
              scrolling="no"
              title="fx."
              src={gamesUrl + "/" + game.url}
              frameBorder="no"
              allowFullScreen={true}
            ></iframe>
          ) : (
            <>
              <div className="mt-20 bg-dark/25 px-4 py-6 mx-auto max-w-4xl w-full rounded-lg backdrop-blur-lg shadow-lg">
                <h2 className="text-xl font-normal text-white text-center">
                  {lang === "en" ? "Subscribe now" : "اشترك الآن"}
                </h2>
                <p className="text-sm font-normal text-white text-center">
                  {lang === "en"
                    ? "Subscribe to enjoy latest games."
                    : "اشترك في منصة شليل للتمتع باحدث الألعاب."}
                </p>

                <button
                  type="button"
                  onClick={() =>
                    redirectToSubscribe(
                      "https://dsplp.sd.zain.com/?p=9534921372"
                    )
                  }
                  className="mt-3 w-full justify-center relative mx-1 tracking-wide inline-flex items-center px-4 py-3 border border-transparent text-lg leading-4 font-medium rounded-md shadow-sm text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 "
                >
                  <div className="relative">
                    <SparklesIcon className="w-4 h-4 ltr:mr-1 rtl:ml-1" />
                    <SparklesIcon className="w-4 h-4 ltr:mr-1 rtl:ml-1 animate-ping absolute top-0" />
                  </div>
                  <span>{lang === "en" ? "Subscribe now" : "إشترك الآن"}</span>
                  {/* </Link> */}
                </button>
              </div>
              <div className="mt-5 bg-dark/25 px-4 py-6 mx-auto max-w-xl w-full rounded-lg backdrop-blur-lg shadow-lg">
                <div className="relative h-80 w-full">
                  <img
                    className="h-full w-full rounded-md"
                    src={apiUrl + "/resources/images/games/" + game.image}
                    alt=""
                  />
                  <p className="text-white text-md line-clamp-1 absolute bottom-0 w-full text-center pb-4 pt-2 bg-gradient-to-t from-black to-black/0 rounded-b-lg">
                    {lang === "en" ? game.name_en : game.name_ar}
                  </p>
                </div>
              </div>
            </>
          )}
          {/* </Sidebar> */}
        </main>
      </React.Fragment>
    </>
  );
}

import React from "react";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import { Link } from "react-router-dom";

export default function Hero() {
  const slides = [
    {
      image: "./assets/images/B.png",
      url: "/game/87",
    },
    {
      image: "./assets/images/C.png",
      url: "/game/86",
    },
    {
      image: "./assets/images/A.png",
      url: "/game/78",
    },
  ];
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    arrows: false,
    // prevArrow: (
    //   <svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     className="h-12 w-12"
    //     fill="none"
    //     viewBox="0 0 24 24"
    //     stroke="currentColor"
    //     strokeWidth="2"
    //   >
    //     <path
    //       strokeLinecap="round"
    //       strokeLinejoin="round"
    //       d="M15 19l-7-7 7-7"
    //     />
    //   </svg>
    // ),
    // nextArrow: (
    //   <svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     className="h-12 w-12"
    //     fill="none"
    //     viewBox="0 0 24 24"
    //     stroke="currentColor"
    //     strokeWidth="2"
    //   >
    //     <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
    //   </svg>
    // ),
  };

  return (
    // <div className="hero flex flex-row justify-between">
    //   <div className="px-8 py-8 md:py-14 md:px-24 md:w-1/2 text-center ltr:md:text-left rtl:md:text-right">
    //     <h3 className="text-base font-bold text-primary">Puzzlers</h3>
    //     <h1 className="py-4 text-5xl font-bold text-white tracking-widest uppercase">
    //       oddBods
    //     </h1>
    //     <p className="text-white text-opacity-70 font-light">
    //       Lorem Ipsum is simply dummy text of the printing and typesetting
    //       industry. Lorem Ipsum has been the industry's standard dummy text ever
    //       since the 1500s.
    //     </p>
    //     <button
    //       type="button"
    //       className="tracking-wide my-4 md:my-6 inline-flex items-center px-8 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400"
    //     >
    //       Play Now
    //     </button>
    //     <button
    //       type="button"
    //       className="ltr:ml-2 rtl:mr-2 tracking-wide inline-flex items-center px-8 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 opacity-80 hover:opacity-100"
    //     >
    //       Learn more
    //     </button>
    //   </div>
    //   <div className="hidden md:block md:px-8 md:py-8 md:w-1/2">
    //     <div className="ltr:text-left rtl:text-right text-xs font-bold">
    //       <img src={heroImage} alt="" className="h-80 w-80" />
    //     </div>
    //   </div>
    // </div>

    // <div className="hero px-16 pt-16 mb-16 shadow-md">
    //   <Slide {...properties}>
    //     {slideImages.map((each, index) => (
    //       <div key={index} className="each-slide rounded-3xl">
    //         <div
    //           style={{ backgroundImage: `url(${each})` }}
    //           className="rounded-3xl rtl:float-right ltr:float-left"
    //         ></div>
    //       </div>
    //     ))}
    //   </Slide>
    // </div>

    // classNames for hero with yellow background: "hero px-4 md:px-8 lg:px-16 pt-4 md:pt-8 lg:pt-16 mb-2 shadow-md"

    <div className="mx-auto max-w-4xl w-full my-2 rounded-3xl shadow-md">
      <Slide {...properties}>
        {slides.map((slide, index) => (
          <Link to={slide.url} key={index} className="each-slide rounded-3xl">
            <img src={slide.image} alt="" className="rounded-3xl" />
          </Link>
        ))}
      </Slide>
    </div>
  );
}

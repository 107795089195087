import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { langToAr, langToEn } from "../../redux/actions/appActions";
import { useDispatch } from "react-redux";

export default function Footer() {
  const lang = useSelector((state) => state.app.lang);
  const dispatch = useDispatch();

  const handleChnageLang = () => {
    if (lang === "ar") {
      // document.documentElement.dir = "ltr";
      // document.documentElement.lang = "en";
      dispatch(langToEn());
    } else {
      // document.documentElement.dir = "rtl";
      // document.documentElement.lang = "ar";
      dispatch(langToAr());
    }
  };

  return (
    <>
      <div className="flex justify-center items-center h-10 w-full bg-darker">
        <Link
          to="/about"
          className="text-sm text-gray-600 hover:text-gray-400 px-1.5"
        >
          {lang === "en" ? "About Shilail" : "عن شليل"}
        </Link>
        <Link
          to="/trending"
          className="text-sm text-gray-600 hover:text-gray-400 px-1.5"
        >
          {lang === "en" ? "Trending" : "الأكثر شعبية"}
        </Link>
        <button
          type="button"
          className="text-sm text-gray-600 hover:text-gray-400 px-1.5"
          onClick={() => handleChnageLang()}
        >
          {lang === "en" ? "العربية" : "English"}
        </button>
      </div>
      <div className="flex justify-center items-center h-10 w-full bg-darker">
        <Link
          to="#"
          className="text-md text-gray-400 px-1.5"
        >
          {lang === "en" ? "Powerd by Ecell " : " Ecell مدعوم بواسطة "}
        </Link>


      </div>
    </>
  );
}

const defaultState = {
  error: false,
  errorTitle: null,
  errorMessage: null,
};

const appReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "GENERIC_ERROR":
      return {
        error: true,
        errorTitle: action.payload.errorTitle,
        errorMessage: action.payload.errorMessage,
      };

    case "CLEAR_ERROR":
      return {
        error: false,
        errorTitle: null,
        errorMessage: null,
      };

    default:
      return state;
  }
};

export default appReducer;

import React, { Fragment, useState, useRef } from "react";
import { Dialog, Transition, Menu, Popover } from "@headlessui/react";
import { SearchIcon, SparklesIcon } from "@heroicons/react/solid";
import {
  BellIcon,
  // MenuIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import {
  classNames,
  responseErrorHandler,
  clearUserData,
} from "../../utils/common";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  authenticatedNavigationEn,
  authenticatedNavigationAr,
  userNavigationEn,
  userNavigationAr,
  subscribedNavigationEn,
  subscribedNavigationAr,
} from "../../utils/navigation";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../redux/actions/authActions";
import * as subscribeActions from "../../redux/actions/subscribeActions";
import { cancelSubscription } from "../../services/subscribe.service";
import { langToAr, langToEn } from "./../../redux/actions/appActions";
import LoadingButtonIcon from "./../../components/LoadingButtonIcon";
import logoAr from "../../assets/img/Logo-Ar-Colored.png";
import logoEn from "../../assets/img/Logo-En-Colored.png";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

export default function Navbar() {
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [submittingCancelSubscription, setSubmittingCancelSubscription] =
    useState(false);

  const SearchSchema = Yup.object().shape({
    keyword: Yup.string()
      .trim()
      .min(2, "First name must be 4 characters at minimum")
      .required("First name is required"),
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const authenticated = useSelector((state) => state.auth.authenticated);
  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang);
  const subscription = useSelector((state) => state.subscription);

  const cancelButtonRef = useRef(null);

  let navigate = useNavigate();

  const authenticatedNavigation =
    lang === "en" ? authenticatedNavigationEn : authenticatedNavigationAr;
  const userNavigation = lang === "en" ? userNavigationEn : userNavigationAr;
  const subscribedNavigation =
    lang === "en" ? subscribedNavigationEn : subscribedNavigationAr;

  const handleLogout = async () => {
    navigate("/");
    dispatch(authActions.logout());
    dispatch(subscribeActions.logout());
  };

  const handleChnageLang = () => {
    if (lang === "ar") {
      // document.documentElement.dir = "ltr";
      // document.documentElement.lang = "en";
      dispatch(langToEn());
    } else {
      // document.documentElement.dir = "rtl";
      // document.documentElement.lang = "ar";
      dispatch(langToAr());
    }
  };

  const handleCancelSubscription = () => {
    setSubmittingCancelSubscription(true);
    cancelSubscription(subscription.subscription.phoneNumber)
      .then((response) => {
        if (response.status === 200) {
          //let updatedUser = user;
          //updatedUser.subscriptions = [];
          //dispatch(authActions.updateUser(updatedUser));
          dispatch(subscribeActions.logout());
          setSubmittingCancelSubscription(false);
          setCancelModalOpen(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 409) {
          //let updatedUser = user;
          //updatedUser.subscriptions = [];
          // dispatch(authActions.updateUser(updatedUser));
          setSubmittingCancelSubscription(false);
          setCancelModalOpen(false);
        } else if (error.response.status === 401) {
          clearUserData();
          navigate("/login?logout=session-timeout");
        } else {
          responseErrorHandler(error, lang);
        }
      });
  };

  const handleSearch = (keyword) => {
    navigate("/search?keyword=" + keyword.target.value);
  };

  const redirectToSubscribe = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleChange = (e) => {
    navigate("/search?keyword=" + e.target.value);
  };

  return (
    <>
      <Transition.Root show={cancelModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setCancelModalOpen}
          initialFocus={cancelButtonRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-dark rounded-lg px-4 pt-5 pb-4 ltr:text-left rtl:text-right overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-100"
                    >
                      {lang === "en" ? "Cancel Subscription" : "إلغاء الاشتراك"}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-300">
                        {lang === "en"
                          ? "After cancelation you will not be able to play games!"
                          : "لن تتمكن من تصفح الألعاب بعد إلغاء الاشتراك!"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
                    onClick={() => handleCancelSubscription()}
                  >
                    {submittingCancelSubscription ? (
                      <LoadingButtonIcon />
                    ) : lang === "en" ? (
                      "Cancel Subscription"
                    ) : (
                      "إلغاء الاشتراك"
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setCancelModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    {lang === "en" ? "Keep Subscription" : "إبقاء الإشتراك"}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? "fixed inset-0 z-40 overflow-y-auto" : "",
            "bg-dark/50 backdrop-blur-lg h-16 shadow-lg relative lg:fixed lg:w-full lg:overflow-y-visible z-50"
          )
        }
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center h-full">
              <div className="relative flex justify-between w-full">
                <div className="flex xl:col-span-2">
                  <div className="flex-shrink-0 flex items-center">
                    {/* <button
                      type="button"
                      className="lg:hidden ltr:-ml-0.5 rtl:-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-shilail-400"
                      onClick={() => setSidebarOpen(true)}
                    >
                      <span className="sr-only">Open sidebar</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}
                    <Link to="/" className="flex flex-row items-center">
                      <img
                        className="block h-8 w-auto"
                        src={lang === "en" ? logoEn : logoAr}
                        alt={lang === "en" ? "Shilail Games" : "شليل للألعاب"}
                      />
                      <div className="flex flex-col justify-start ltr:pl-2 rtl:pr-2 ltr:ml-2 rtl:mr-2 ltr:border-l rtl:border-r border-gray-500">
                        {/* <span className="text-[10px] text-gray-400">
                          {lang === "en" ? "Powered by" : "بالتعاون مع"}
                        </span> */}
                        <img
                          className="block h-6 w-auto mt-0.5"
                          src="/assets/logo/zain-logo.png"
                          alt={lang === "en" ? "Zain - Sudan" : "زين - السودان"}
                        />
                      </div>
                    </Link>
                  </div>
                </div>

                {/* <div className="min-w-0 flex-1 px-4 xl:col-span-6 hidden md:block">
                  <div className="flex items-center px-6 lg:max-w-3xl lg:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                    <form className="w-full">
                      <label htmlFor="search" className="sr-only">
                        Search
                      </label>
                      <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 ltr:pl-3 rtl:pr-3 flex items-center">
                          <SearchIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="search"
                          name="keyword"
                          className={`block w-full bg-darker/25 backdrop-blur-lg rounded-full py-2 ltr:pl-10 rtl:pr-10 ltr:pr-3 rtl:pl-3 text-white text-sm placeholder-gray-500 focus:outline-none sm:text-sm focus:text-white focus:placeholder-gray-400 focus:ring-1`}
                          placeholder="Search"
                          type="text"
                          onChange={handleSearch}
                        />
                      </div>
                    </form>
                  </div>
                </div> */}

                <div className="flex items-center justify-end flex-1">
                  {/* Language switcher */}
                  <button
                    type="button"
                    className="hidden sm:block inline-flex items-center px-3 py-2 border border-gray-800 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400"
                    onClick={() => handleChnageLang()}
                  >
                    {lang === "ar" ? "English" : "العربية"}
                  </button>
                  {/* End Language switcher */}

                  {/*{authenticated ? (*/}
                  <>
                    {subscription.subscription === null ? (
                      // <button
                      //   type="button"
                      //   className="block rounded-md py-2 px-3 text-sm font-medium text-red-600 border border-transparent hover:border-red-600"
                      //   onClick={() => setCancelModalOpen(true)}
                      // >
                      //   Cancel subscription
                      // </button>
                      // <Link
                      //   to="/subscribe"
                      //   className="w-full sm:w-auto justify-center relative mx-1 tracking-wide inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 relative"
                      // >
                      //   <SparklesIcon className="w-4 h-4 ltr:mr-1 rtl:ml-1" />
                      //   <SparklesIcon className="w-4 h-4 ltr:mr-1 rtl:ml-1 animate-ping absolute" />
                      //   {lang === "en" ? "Subscribe now" : "إشترك الآن"}
                      // </Link>
                      <button
                        onClick={() =>
                          redirectToSubscribe(
                            "https://dsplp.sd.zain.com/?p=9534921372"
                          )
                        }
                        className="w-full sm:w-auto justify-center relative mx-1 tracking-wide inline-flex items-center px-8 py-2  border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 relative"
                      >
                        <SparklesIcon className="w-4 h-4 ltr:mr-1 rtl:ml-1 animate-ping absolute" />
                        {lang === "en" ? "Subscribe now" : "الإشتراك"}
                      </button>
                    ) : (
                      <button
                        onClick={() => setCancelModalOpen(true)}
                        type="button"
                        className="w-full sm:w-auto justify-center relative mx-1 tracking-wide inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 relative"
                      >
                        {lang === "en"
                          ? "Cancel Subscription"
                          : "إلغاء الاشتراك"}
                      </button>
                    )}

                    {/*<Menu
                      as="div"
                      className="flex-shrink-0 relative ltr:ml-2 rtl:mr-2"
                    >
                      <div>
                        <Menu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400">
                          <span className="sr-only">Open user menu</span>
                          {user.avatar ? (
                            <img
                              className="h-8 w-8 rounded-full"
                              src={user.avatar}
                              alt=""
                            />
                          ) : (
                            <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                              <svg
                                className="h-full w-full text-gray-300"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                              </svg>
                            </span>
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute z-50 ltr:right-0 rtl:left-0 mt-2 w-48 rounded-md shadow-lg bg-dark ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                          {(user.subscriptions.length > 0 ||
                            subscription.subscription !== null) && (
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => setCancelModalOpen(true)}
                                  type="button"
                                  className={classNames(
                                    active ? "bg-darker" : "",
                                    "w-full ltr:text-left rtl:text-right text-red-600 hover:text-red-600 block py-2 px-4 text-sm"
                                  )}
                                >
                                  {lang === "en"
                                    ? "Cancel Subscription"
                                    : "إلغاء الاشتراك"}
                                </button>
                              )}
                            </Menu.Item>
                          )}
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    active ? "bg-darker" : "",
                                    "text-dark hover:text-white block py-2 px-4 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={handleLogout}
                                type="button"
                                className={classNames(
                                  active ? "bg-darker" : "",
                                  "w-full text-dark hover:text-white block py-2 px-4 text-sm text-gray-700 ltr:text-left rtl:text-right"
                                )}
                              >
                                {lang === "en" ? "Logout" : "تسجيل الخروج"}
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>*/}
                  </>
                  {/*) : (
                    <Fragment>
                      {/* <button
                        type="button"
                        onClick={() => {
                          dispatch(authActions.login("912300000", "password"));
                        }}
                        className="w-full sm:w-auto justify-center relative ltr:ml-4 rtl:mr-4 sm:mr-1 sm:ml-1 tracking-wide inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 relative"
                      >
                        <SparklesIcon className="w-4 h-4 ltr:mr-1 rtl:ml-1" />
                        <SparklesIcon className="w-4 h-4 ltr:mr-1 rtl:ml-1 animate-ping absolute" />
                        {lang === "en" ? "Subscribe" : "الإشتراك"}
                      </button> }
                      <Link
                        to="/login"
                        className="w-full sm:w-auto justify-center relative ltr:ml-4 rtl:mr-4 sm:mr-1 sm:ml-1 tracking-wide inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-shilail-500 hover:bg-shilail-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400 relative"
                      >
                        {/* <SparklesIcon className="w-4 h-4 ltr:mr-1 rtl:ml-1" />
                        <SparklesIcon className="w-4 h-4 ltr:mr-1 rtl:ml-1 animate-ping absolute" /> }
                        {lang === "en" ? "Login" : "تسجيل الدخول"}
                      </Link>
                    </Fragment>
                      )}*/}
                </div>
              </div>
            </div>

            {/* {authenticated ? (
              user.subscriptions.length > 0 ? (
                <Popover.Panel
                  as="nav"
                  className="lg:hidden"
                  aria-label="Global"
                >
                  <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                    {subscribedNavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.href === location.pathname
                            ? "bg-gray-100 text-gray-900"
                            : "hover:bg-gray-50",
                          "block rounded-md py-2 px-3 text-base font-medium"
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="border-t border-gray-200 pt-4 pb-3">
                    <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                      <div className="flex-shrink-0">
                        {user.avatar ? (
                          <img
                            className="h-10 w-10 rounded-full"
                            src={user.avatar}
                            alt=""
                          />
                        ) : (
                          <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                            <svg
                              className="h-full w-full text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </span>
                        )}
                      </div>
                      <div className="ltr:ml-3 rtl:mr-3">
                        <div className="text-base font-medium text-gray-800">
                          {user.name}
                        </div>
                        <div className="text-sm font-medium text-gray-500">
                          {user.email}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="ltr:ml-auto rtl:mr-auto flex-shrink-0 bg-dark rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                      {userNavigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              ) : (
                <Popover.Panel
                  as="nav"
                  className="lg:hidden"
                  aria-label="Global"
                >
                  <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                    {authenticatedNavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.href === location.pathname
                            ? "bg-gray-100 text-gray-900"
                            : "hover:bg-gray-50",
                          "block rounded-md py-2 px-3 text-base font-medium"
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className="border-t border-gray-200 pt-4 pb-3">
                    <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                      <div className="flex-shrink-0">
                        {user.avatar ? (
                          <img
                            className="h-01 w-10 rounded-full"
                            src={user.avatar}
                            alt=""
                          />
                        ) : (
                          <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                            <svg
                              className="h-full w-full text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </span>
                        )}
                      </div>
                      <div className="ltr:ml-3 rtl:mr-3">
                        <div className="text-base font-medium text-gray-800">
                          {user.name}
                        </div>
                        <div className="text-sm font-medium text-gray-500">
                          {user.email}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="ltr:ml-auto rtl:mr-auto flex-shrink-0 bg-dark rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-shilail-400"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                      {userNavigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              )
            ) : null} */}
          </>
        )}
      </Popover>
    </>
  );
}
